<template>
    <PageSection :resource="resource"
                 :id="resource.slug"
                 :carousel-component="carouselComponent"
                 class="default-section"
    >
        <PageSummary :resource="resource"/>
        <slot></slot>
    </PageSection>
</template>

<script>
    import PageSection from './PageSection';
    import PageSummary from '../PageSummary';

    export default {
        components: {
            PageSection,
            PageSummary
        },

        props: {
            resource: Object,
            carouselComponent: {
                type: String,
                default: 'HeroCarousel'
            }
        }
    }
</script>