<template>
    <div class="img-wall__brick">
        <div v-if="isSingleImg"
             class="img-wall__brick__mortar"
        >
            <SquareImg :src="content.image"
                       rollover
                       @click="$emit('click', content)"
            />
        </div>
        <template v-else>
            <div class="img-wall__brick__row img-wall__brick__row--upper">
                <div class="img-wall__brick__col">
                    <div class="img-wall__brick__mortar">
                        <SquareImg :src="content[0].image"
                                   rollover
                                   @click="$emit('click', content[0])"
                        />
                    </div>
                </div>
            </div>
            <div v-if="content.length > 1"
                 class="img-wall__brick__row img-wall__brick__row--lower"
            >
                <div class="img-wall__brick__col">
                    <div class="img-wall__brick__mortar">
                        <SquareImg :src="content[1].image"
                                   rollover
                                   @click="$emit('click', content[1])"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        props: {
            content: [Array, Object]
        },

        computed: {
            isSingleImg() {
                return !Array.isArray(this.content);
            }
        }
    }
</script>

<style lang="scss">
    .img-wall__brick {
        &__row {
            &--upper {
                .img-wall__brick__col {
                    margin-left: auto;
                }
            }
        }

        &__col {
            width: 50%;
        }

        &__mortar {
            padding: 7px;
        }

        .square-img {
            cursor: pointer;
        }
    }
</style>