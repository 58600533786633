<template>
    <div v-if="items.length"
         class="quote-carousel"
         ref="container"
    >
        <BaseImg :src="backgroundImageSrc"
                 :alt="backgroundImageAlt"
                 class="quote-carousel__background-img"
                 :style="backgroundImgStyles"
        />
        <div class="quote-carousel__overlay"></div>
        <slick :options="slickOptions"
               class="quote-carousel__slider"
        >
            <div v-for="item in items"
                 :key="item.id"
                 class="quote-carousel__item"
            >
                <template v-if="item.strapline || item.byline">
                    <BContainer class="quote-carousel__item__text">
                        <BaseBox class="py-0">
                            <div v-if="item.strapline"
                                 v-html="item.strapline"
                                 class="h1 quote-carousel__item__text__strapline"
                            ></div>
                            <div v-if="item.byline"
                                 v-html="item.byline"
                                 class="quote-carousel__item__text__byline"
                            ></div>
                        </BaseBox>
                    </BContainer>
                </template>
            </div>
        </slick>
        <BContainer class="quote-carousel__item__credit-container">
            <BaseBox class="py-0">
                <PhotographyCredit :strapline="backgroundImageCopyright"/>
            </BaseBox>
        </BContainer>
    </div>
</template>

<script>
    import Slick from 'vue-slick';
    import PhotographyCredit from './PhotographyCredit';

    export default {
        components: {
            Slick,
            PhotographyCredit
        },

        props: {
            items: Array,
            backgroundImageSrc: String,
            backgroundImageAlt: String,
            backgroundImageCopyright: String
        },

        data() {
            return {
                slickOptions: {
                    slidesToShow: 1,
                    arrows: false,
                    dots: true,
                    fade: true,
                    speed: 500,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                dots: false
                            }
                        }
                    ]
                },
                imgHeight: null,
                imgOffset: null,
                windowHeight: null
            };
        },

        computed: {
            backgroundImgStyles() {
                let factor = 40;
                let imgHeight = 100 + factor;
                let viewportCentre = (this.windowHeight - this.imgHeight) / 2;
                let track = (this.imgHeight + viewportCentre) * 2;
                let position = this.imgOffset - viewportCentre;
                let top = (((position / track) * 2) * (factor/2)) - (factor/2);

                return {
                    height: `${imgHeight}%`,
                    top: `${top}%`
                }
            }
        },

        mounted() {
            this.imgHeight = this.$refs.container.offsetHeight;
            this.imgOffset = this.$refs.container.getBoundingClientRect().top;
            this.windowHeight = window.innerHeight;

            window.addEventListener('scroll', () => {
                this.imgOffset = this.$refs.container.getBoundingClientRect().top;
            });

            window.addEventListener('resize', () => {
                this.imgHeight = this.$refs.container.offsetHeight;
                this.windowHeight = window.innerHeight;
            });
        }
    }
</script>

<style lang="scss">
    .quote-carousel {
        height: 350px;
        overflow: hidden;
        position: relative;

        @include media-breakpoint-up(lg) {
            height: 500px;
        }

        @include media-breakpoint-up(xl) {
            height: 725px;
        }

        &__background-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            object-fit: cover;
        }

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: map-get($palette, "black");
            opacity: .4;
        }

        &__slider {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &__item {
            position: relative;
            height: 100%;

            &__text {
                z-index: 2;
                color: map-get($palette, "white");
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                text-align: center;
                transform: translateY(-50%);

                @include media-breakpoint-up(xl) {
                    padding: 0 200px;
                }

                &__strapline {
                    margin-bottom: 25px;
                    @include font-rhythm(14);

                    @include media-breakpoint-up(lg) {
                        margin-bottom: 75px;
                        @include font-rhythm(23);
                    }
                }
            }

            &__credit-container {
                position: absolute;
                bottom: 20px;
                left: 0;
                right: 0;
                z-index: 1;

                .photography-credit {
                    width: fit-content;
                    margin-left: auto;
                }
            }

            .base-img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .slick-dots {
            display: none;

            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: center;
                position: absolute;
                bottom: 0;
                width: 100%;
                list-style: none;
                margin-bottom: 50px;

                li {
                    margin: 0 5px;

                    button {
                        display: block;
                        background: none;
                        border: solid 2px map-get($palette, "white");
                        border-radius: 50%;
                        transition: background-color 400ms;
                        width: 20px;
                        height: 20px;
                        overflow: hidden;
                        text-indent: 25px;
                        outline: none !important;
                    }

                    &.slick-active {
                        button {
                            background-color: map-get($palette, "white");
                        }
                    }
                }
            }
        }

        .slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div {
            height: 100%;
        }
    }
</style>