<template>
    <div id="master-layout">
        <TheHeader/>
        <main id="master-layout__content">
            <slot></slot>
        </main>
    </div>
</template>

<script>
    import TheHeader from '../templates/partials/TheHeader';

    export default {
        components: {
            TheHeader
        }
    }
</script>

<style lang="scss">
    #master-layout {
        overflow-x: hidden;

        &__content {
            padding-top: 126px;

            @include media-breakpoint-up(lg) {
                padding-top: 113px;
            }

            @include media-breakpoint-up(xl) {
                padding-top: 241px;
            }
        }

        .fade-enter-active, .fade-leave-active {
            transition-duration: 600ms;
            transition-property: opacity;
            transition-timing-function: ease;
        }

        .fade-enter-active {
            transition-delay: 400ms;
        }

        .fade-enter, .fade-leave-active {
            opacity: 0
        }
    }
</style>