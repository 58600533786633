<template>
    <DefaultSection :resource="resource"
                    :id="resource.slug"
                    class="portfolio-section"
    >
        <BaseBox class="py-0">
            <BContainer>
                <BRow class="portfolio-section__row">
                    <BCol cols="4"
                          lg="3"
                          v-for="item in portfolio"
                          :key="item.id"
                          class="portfolio-section__item"
                          :class="`portfolio-section__item--${item.key}`"
                    >
                        <BaseImg :src="item.image"
                                 :alt="item.title"
                                 class="portfolio-section__item__asset"
                        />
                    </BCol>
                </BRow>
            </BContainer>
        </BaseBox>
    </DefaultSection>
</template>

<script>
    import DefaultSection from './DefaultSection';
    import Models from '../../../../../models/factory';

    export default {
        components: {
            DefaultSection
        },

        props: {
            resource: Object
        },

        data() {
            return {
                portfolio: []
            }
        },

        mounted() {
            Models.portfolio().query().where('published', 1).where('deleted_at', null).sort('sort').get().then(items => {
                this.portfolio = items.filter(item => {
                    return item.title;
                }).map(item => {
                    item.key = item.title.toLowerCase().replaceAll(' ', '-');

                    return item;
                });
            });
        }
    }
</script>

<style lang="scss">
    .portfolio-section {
        margin-bottom: 60px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 90px;
        }

        &__item {
            display: flex;
            align-items: center;
            margin-top: 35px;

            &:nth-child(-n+3) {
                margin-top: 0;
            }

            &:nth-child(-n+4) {
                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                }
            }

            &--mtv {
                .portfolio-section__item__asset {
                    max-width: 62px;

                    @include media-breakpoint-up(lg) {
                        max-width: 125px;
                    }
                }
            }

            &--american-express {
                .portfolio-section__item__asset {
                    max-width: 48px;

                    @include media-breakpoint-up(lg) {
                        max-width: 95px;
                    }
                }
            }

            &--microsoft {
                .portfolio-section__item__asset {
                    max-width: 70px;

                    @include media-breakpoint-up(lg) {
                        max-width: 208px;
                    }
                }
            }

            &--bobbi-brown {
                .portfolio-section__item__asset {
                    max-width: 76px;

                    @include media-breakpoint-up(lg) {
                        max-width: 196px;
                    }
                }
            }

            &--american-airlines {
                .portfolio-section__item__asset {
                    max-width: 76px;

                    @include media-breakpoint-up(lg) {
                        max-width: 230px;
                    }
                }
            }

            &--holiday-inn {
                .portfolio-section__item__asset {
                    max-width: 49px;

                    @include media-breakpoint-up(lg) {
                        max-width: 129px;
                    }
                }
            }

            &--hunter {
                .portfolio-section__item__asset {
                    max-width: 69px;

                    @include media-breakpoint-up(lg) {
                        max-width: 174px;
                    }
                }
            }

            &--warner-music-group {
                .portfolio-section__item__asset {
                    max-width: 70px;

                    @include media-breakpoint-up(lg) {
                        max-width: 188px;
                    }
                }
            }

            &--samsung {
                .portfolio-section__item__asset {
                    max-width: 70px;

                    @include media-breakpoint-up(lg) {
                        max-width: 208px;
                    }
                }
            }

            &--ray-ban {
                .portfolio-section__item__asset {
                    max-width: 70px;

                    @include media-breakpoint-up(lg) {
                        max-width: 163px;
                    }
                }
            }

            &--luxottica {
                .portfolio-section__item__asset {
                    max-width: 70px;

                    @include media-breakpoint-up(lg) {
                        max-width: 190px;
                    }
                }
            }

            &--iweigh {
                .portfolio-section__item__asset {
                    max-width: 70px;

                    @include media-breakpoint-up(lg) {
                        max-width: 159px;
                    }
                }
            }

            &--from-babies-with-love {
                .portfolio-section__item__asset {
                    max-width: 70px;

                    @include media-breakpoint-up(lg) {
                        max-width: 185px;
                    }
                }
            }

            &--oliver-peoples {
                .portfolio-section__item__asset {
                    max-width: 85px;

                    @include media-breakpoint-up(lg) {
                        max-width: 226px;
                    }
                }
            }

            &__asset {
                margin: 0 auto;
            }
        }
    }
</style>