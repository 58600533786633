<template>
    <DefaultSection :resource="resource"
                    :id="resource.slug"
                    class="contact-section"
    >
        <div class="contact-section__overlay"></div>
        <BaseImg :src="resource.image"
                 class="contact-section__img"
        />
        <div class="contact-section__details">
            <BContainer>
                <a class="h1 contact-section__details__email"
                   :href="`mailto:${email}`"
                >
                    {{ email }}
                </a>
                <SocialLinks/>
            </BContainer>
        </div>
        <BContainer class="contact-section__devs">
            <BaseBox class="py-0">
                Designed and developed by
                <a href="https://www.onlinesolutionsltd.com/"
                   target="_blank"
                >
                    <OSLogo/>
                </a>
            </BaseBox>
        </BContainer>
    </DefaultSection>
</template>

<script>
    import DefaultSection from './DefaultSection';
    import SocialLinks from '../../../../social/SocialLinks';
    import OSLogo from '../OSLogo';

    export default {
        components: {
            DefaultSection,
            SocialLinks,
            OSLogo
        },

        props: {
            resource: Object
        },

        computed: {
            email() {
                return this.$store.getters.keyValue('contact', 'contact-email').value;
            },
        }
    }
</script>

<style lang="scss">
    .contact-section {
        margin-top: 80px;
        height: 320px;
        overflow: hidden;
        color: map-get($palette, "white");

        @include media-breakpoint-up(lg) {
            height: 620px;
        }

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: map-get($palette, "black");
            opacity: .4;
        }

        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center top;

            @include media-breakpoint-up(lg) {
                object-position: left top;
            }
        }

        &__details {
            position: absolute;
            top: 38%;
            left: 0;
            right: 0;
            text-align: center;

            @include media-breakpoint-up(lg) {
                top: 41%;
            }

            &__email {
                display: block;
                margin-bottom: 15px;
                text-transform: uppercase;
                @include font-rhythm(23);

                @include media-breakpoint-up(lg) {
                    margin-bottom: 70px;
                    @include font-rhythm(40);
                }
            }

            .social-links {
                justify-content: center;

                &__item {
                    width: 40px;

                    @include media-breakpoint-up(lg) {
                        width: 60px;
                    }
                }

                .icon-instagram, .icon-twitter {
                    &__symbol {
                        fill: map-get($palette, "white");
                    }
                }

                circle {
                    stroke: map-get($palette, "white");
                }
            }
        }

        &__devs {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            padding-bottom: 20px;
            @include font-rhythm(11);

            @include media-breakpoint-up(lg) {
                text-align: right;
                padding-bottom: 50px;
            }

            .os-logo {
                width: 185px;
                margin: 5px auto 0 auto;

                @include media-breakpoint-up(lg) {
                    text-align: right;
                    margin: 0 0 0 auto;
                }
            }
        }

        .page-section__content {
            height: 100%;
        }

        .section-heading {
            position: absolute;
            color: map-get($palette, "white");
            margin-top: 25px;
            z-index: 1;

            @include media-breakpoint-up(lg) {
                margin-top: 60px;
            }

            &__icon {
                path {
                    fill: map-get($palette, "white");
                }
            }
        }
    }
</style>