<template>
    <div class="base-poster">
        <div class="base-poster__img">
            <RoundImg :src="imgSrc"
                      @click="$emit('click')"
            />
        </div>
        <div class="base-poster__text">
            <h4 @click="$emit('click')"
                class="h1 link"
            >
                {{ title }}
            </h4>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            imgSrc: String,
            title: String
        }
    }
</script>

<style lang="scss">
    .base-poster {
        display: flex;
        margin-bottom: 40px;

        @include media-breakpoint-up(md) {
            margin-bottom: 60px;
        }

        &__img {
            min-width: 75px;

            @include media-breakpoint-up(md) {
                min-width: 150px;
            }
        }

        &__text {
            padding-left: 20px;

            @include media-breakpoint-up(md) {
                padding: 0 40px 0 30px;
            }
        }
    }
</style>