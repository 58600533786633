<template>
    <nav class="the-nav">
        <router-link v-for="item in items"
                     :key="item.name"
                     :to="{ name: 'home', params: { slug: item.slug } }"
                     class="the-nav__item"
        >
            {{ item.text }}
            <div class="the-nav__item__icon">
                <IconTilde/>
            </div>
        </router-link>
    </nav>
</template>

<script>
    import IconTilde from '../../../base/icons/IconTilde';

    export default {
        components: {
            IconTilde
        },

        computed: {
            items() {
                return this.$store.getters.sectionHomepages.filter(item => {
                    return !item.hideInNav;
                }).map(item => {
                    return {
                        slug: item.slug,
                        text: item.navTitle
                    };
                })
            }
        }
    }
</script>

<style lang="scss">
    .the-nav {
        display: none;

        @include media-breakpoint-up(xl) {
            display: flex;
            margin: 0 -22px;
            text-transform: uppercase;
            letter-spacing: 2.5px;
            justify-content: center;
            @include font-rhythm(12);

            &__item {
                display: block;
                color: inherit;
                margin: 0 22px;
                cursor: pointer;
                transition: color 400ms;
                position: relative;
                text-decoration: none !important;

                &:hover {
                    color: map-get($palette, "heliotrope");

                    .the-nav__item__icon {
                        opacity: 1;
                    }
                }

                &__icon {
                    position: absolute;
                    bottom: -13px;
                    width: 100%;
                    opacity: 0;
                    transition: opacity 400ms;
                    pointer-events: none;

                    svg {
                        width: 25px;
                        margin: 0 auto;

                        path {
                            fill: map-get($palette, "heliotrope")
                        }
                    }
                }
            }
        }
    }
</style>