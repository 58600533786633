import Path from '../path';
import Blender from '../../utils/blender';

class Resource {
    constructor(model) {
        this._model = model;
        this._path = new Path(model._uri);
    }

    $mix(mixins) {
        Blender.on(this).mix(mixins);

        return this;
    }

    $fill(attributes) {
        Object.assign(this, attributes);

        return this;
    }

    $properties() {
        let properties = {};

        for(let prop in this) {
            if(prop.charAt(0) !== '_' && typeof this[prop] !== 'function') {
                properties[prop] = this[prop];
            }
        }

        return properties;
    }

    $attributes() {
        let attributes = {};
        let properties = this.$properties();

        for(let prop in properties) {
            if( ! this._path.includes(prop)) {
                attributes[prop] = properties[prop];
            }
        }

        return attributes;
    }

    $foreignKeys() {
        let keys = {};
        let properties = this.$properties();

        for(let prop in properties) {
            if(this._path.includes(prop)) {
                keys[prop] = properties[prop];
            }
        }

        return keys;
    }
}

export default Resource;
