<template>
    <MasterLayout>
        <transition name="fade"
                    mode="out-in"
        >
            <router-view></router-view>
        </transition>
    </MasterLayout>
</template>

<script>
    import MasterLayout from './components/views/layouts/MasterLayout';

    export default {
        components: {
            MasterLayout
        }
    }
</script>