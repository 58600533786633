<template>
    <component :is="element"
               class="section-heading h2"
    >
        <slot></slot>
        <IconTilde class="section-heading__icon" />
    </component>
</template>

<script>
    import IconTilde from './icons/IconTilde';

    export default {
        components: {
            IconTilde
        },

        props: {
            element: {
                type: String,
                default: 'h2'
            }
        }
    }
</script>

<style lang="scss">
    .section-heading {
        text-transform: uppercase;
        color: map-get($palette, "heliotrope");
        margin-bottom: 30px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 65px;
        }

        &__icon {
            width: 40px;
            margin-top: 15px;

            path {
                fill: map-get($palette, "heliotrope");
            }
        }
    }
</style>