class Path {
    constructor(uri) {
        this._uri = uri;
        this._bindings = [];
    }

    resolve(uri) {
        uri = typeof uri !== 'undefined' ? uri : this._uri;

        if (uri) {
            for (let binding in this._bindings) {
                uri = uri.replace('{' + binding + '}', this._bindings[binding]);
            }
        }

        return uri;
    }

    includes(property, uri = null) {
        uri = typeof uri !== 'undefined' && uri !== null ? uri : this._uri;

        return uri.includes('{' + property + '}');
    }

    bind(property, value) {
        this._bindings[property] = value;

        return this;
    }

    isBound(property) {
        return typeof this._bindings[property] !== 'undefined';
    }

    prepare(map, uri) {
        for (let prop in map) {
            if (this.includes(prop, uri)) {
                this.bind(prop, map[prop]);
            }
        }

        return this;
    }
}

export default Path;