import Vuex from 'vuex';

const store = new Vuex.Store({
    state: {
        hierarchy: [],
        keyValues: []
    },

    getters: {
        sectionHomepages: state => {
            return state.hierarchy.map(section => {
                return section.pages.find(page => {
                    return page.sectionHomepage === 1;
                });
            });
        },

        pages: state => {
            let pages = [];

            state.hierarchy.forEach(section => {
                pages = pages.concat(section.pages);
            });

            return pages;
        },

        pageBySlug: (state, getters) => (slug) => {
            return getters.pages.find(page => {
                return page.slug === slug;
            });
        },

        keyValue: (state) => (category, title) => {
            return state.keyValues.find(keyValue => {
                return keyValue.category === category && keyValue.title === title;
            });
        },
    },

    mutations: {
        fillHierarchy(state, hierarchy) {
            state.hierarchy = hierarchy;
        },

        fillKeyValues(state, keyValues) {
            state.keyValues = keyValues;
        }
    }
});

export default store;