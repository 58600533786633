<template>
    <div v-if="items.length"
         class="news-carousel"
    >
        <slick :options="slickOptions">
            <NewsPoster v-for="item in items"
                        :key="item.id"
                        :resource="item"
            />
        </slick>
    </div>
</template>

<script>
    import Slick from 'vue-slick';

    export default {
        components: {
            Slick
        },

        props: {
            items: Array
        },

        data() {
            return {
                slickOptions: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows: false,
                    dots: true,
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                centerMode: true,
                                centerPadding: '40px',
                                dots: false,
                            }
                        }
                    ]
                }
            };
        },
    }
</script>

<style lang="scss">
    .news-carousel {
        overflow: hidden;

        .news-poster {
            padding: 0 8px;
        }

        .slick-list {
            margin: 0 -8px;
            padding-left: 0 !important;
        }

        .slick-dots {
            display: none;

            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                bottom: 0;
                width: 100%;
                list-style: none;
                margin-top: 30px;

                li {
                    margin: 0 5px;

                    button {
                        display: block;
                        background: none;
                        border: solid 2px map-get($palette, "mine-shaft");
                        border-radius: 50%;
                        transition: background-color 400ms;
                        width: 20px;
                        height: 20px;
                        overflow: hidden;
                        text-indent: 25px;
                        outline: none !important;
                    }

                    &.slick-active {
                        button {
                            background-color: map-get($palette, "mine-shaft");
                        }
                    }
                }
            }
        }
    }
</style>