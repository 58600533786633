class Repacker {

    // TODO: Handle relationships

    constructor(type, payload) {
        this._type = type;
        this._payload = payload;
    }

    data() {
        let id = this.id();
        let attributes = this.attributes();
        let data = {
            type: this.type()
        };

        if (id) {
            data.id = id;
        }

        if (attributes) {
            data.attributes = attributes;
        }

        return {data};
    }

    type() {
        return this._type;
    }

    id() {
        return typeof this._payload.id !== 'undefined' && this._payload.id ? this._payload.id : null;
    }

    attributes() {
        let attributes = JSON.parse(JSON.stringify(this._payload));

        if (typeof attributes.id !== 'undefined') {
            delete attributes.id;
        }

        return attributes;
    }
}

export default Repacker;
