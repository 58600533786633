<template>
    <div id="home-page"
         class="page"
    >
        <component v-for="section in sections"
                   :key="section.resource.id"
                   :is="section.component"
                   :resource="section.resource"
                   :ref="section.resource.slug"
                   :id="section.resource.slug"
        ></component>
    </div>
</template>

<script>
    import DefaultSection from './partials/sections/DefaultSection';
    import ExperienceSection from './partials/sections/ExperienceSection';
    import PortfolioSection from './partials/sections/PortfolioSection';
    import PressSection from './partials/sections/PressSection';
    import ContactSection from './partials/sections/ContactSection';

    export default {
        components: {
            DefaultSection,
            ExperienceSection,
            PortfolioSection,
            PressSection,
            ContactSection
        },

        props: {
            slug: String
        },

        watch: {
            slug(val) {
                this.goTo(val);
            }
        },

        computed: {
            pages() {
                return this.$store.getters.sectionHomepages;
            },

            sections() {
                return this.pages.map(page => {
                    let component = 'DefaultSection';

                    switch (page.slug) {
                        case 'experience':
                            component = 'ExperienceSection';
                            break;

                        case 'client-portfolio':
                            component = 'PortfolioSection';
                            break;

                        case 'press':
                            component = 'PressSection';
                            break;

                        case 'contact':
                            component = 'ContactSection';
                            break;
                    }

                    return {
                        component,
                        resource: page
                    }
                })
            }
        },

        methods: {
            goTo(slug) {
                if (typeof this.$refs[slug] !== 'undefined') {
                    window.scrollTo({
                        top: this.$refs[slug][0].$el.querySelector('.page-section__title').getBoundingClientRect().top + window.pageYOffset - 135,
                        behavior: "smooth"
                    });
                }
            }
        },

        mounted() {
            if (this.slug) {
                window.setTimeout(() => {
                    this.goTo(this.slug);
                }, 500);
            }
        }
    }
</script>