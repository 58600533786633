<template>
    <div :class="classes"
         class="light-box"
    >
        <BaseBox>
            <BContainer class="light-box__container">
                <IconCross @click="$emit('close')"
                           class="light-box__close"
                />
                <div class="light-box__gallery">
                    <div class="light-box__gallery__upper">
                        <div class="light-box__gallery__control light-box__gallery__control--previous">
                            <div v-if="items && items.length > 1"
                                 @click="previous"
                                 class="light-box__gallery__control__trigger"
                            >
                                Previous
                            </div>
                        </div>
                        <div class="light-box__gallery__images"
                             ref="galleryImageContainer"
                        >
                            <BaseImg v-for="(item, index) in items"
                                     :key="index"
                                     @animationend="animationEnd(item)"
                                     class="light-box__gallery__images__image"
                                     :src="item.image"
                                     :alt="item.imageAlt"
                                     :class="{
                                         'light-box__gallery__images__image--in': isIn === item,
                                         'light-box__gallery__images__image--out': isOut === item
                                     }"
                            />
                        </div>
                        <div class="light-box__gallery__control light-box__gallery__control--next">
                            <div v-if="items && items.length > 1"
                                 @click="next"
                                 class="light-box__gallery__control__trigger"
                            >
                                Next
                            </div>
                        </div>
                    </div>
                    <div class="light-box__gallery__lower">
                        <div v-for="(item, index) in items"
                             :key="index"
                             class="light-box__gallery__text"
                        >
                            <div v-if="item.body"
                                 :class="{
                                     'light-box__gallery__text__item--in': isIn === item,
                                     'light-box__gallery__text__item--out': isOut === item
                                 }"
                                 class="light-box__gallery__text__item"
                            >
                                <div v-html="item.title"
                                     class="light-box__gallery__text__item__title"
                                ></div>
                                <div v-html="item.body"
                                     class="light-box__gallery__text__item__summary"
                                ></div>
                                <PhotographyCredit :strapline="item.imageCopyright"
                                                   class="light-box__gallery__text__item__credit"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </BContainer>
        </BaseBox>
    </div>
</template>

<script>
    import IconCross from '../base/icons/IconCross';
    import PhotographyCredit from '../views/templates/partials/PhotographyCredit';
    import Hammer from 'hammerjs';

    export default {
        components: {
            IconCross,
            PhotographyCredit
        },

        props: {
            visible: {
                type: Boolean,
                default: false
            },
            items: Array
        },

        data() {
            return {
                isIn: null,
                isOut: null,
                direction: null
            }
        },

        watch: {
            items(val) {
                if (Array.isArray(val) && val.length) {
                    this.isIn = val[0];
                }
            }
        },

        computed: {
            classes() {
                let classes = [];

                if (this.visible) {
                    classes.push('light-box--visible');
                }

                return classes;
            }
        },

        methods: {
            next() {
                this.direction = 'next';
                this.isOut = this.isIn;
            },

            previous() {
                this.direction = 'previous';
                this.isOut = this.isIn;
            },

            animationEnd(item) {
                if (item === this.isOut) {
                    let index = this.items.indexOf(this.isIn);

                    if (this.direction === 'next') {
                        index = index === this.items.length - 1 ? 0 : index + 1;
                    } else {
                        index = index === 0 ? this.items.length - 1 : index - 1;
                    }

                    this.isOut = null;
                    this.isIn = this.items[index];
                }
            }
        },

        mounted() {
            let detector = new Hammer(this.$refs.galleryImageContainer);

            detector.on('swipe', (event) => {
                switch (event.direction) {
                    case 2:
                        this.next();
                        break;

                    case 4:
                        this.previous();
                        break;
                }
            });
        }
    }
</script>

<style lang="scss">
    .light-box {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: map-get($palette, "mine-shaft");
        z-index: 3;
        opacity: 0;
        transition: opacity 400ms;
        pointer-events: none;
        color: map_get($palette, "white");

        &--visible {
            opacity: 1;
            pointer-events: auto;

            .light-box__close, .light-box__gallery__control, .light-box__gallery__images, .light-box__gallery__text {
                animation: bringIn 400ms forwards;
            }

            .light-box__close, .light-box__gallery__control, .light-box__gallery__text {
                animation-delay: 600ms;
            }

            .light-box__gallery__images {
                animation-delay: 400ms;
            }
        }

        &__close {
            width: 25px;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            opacity: 0;
            transform: translateX(100px);
            z-index: 1;

            @include media-breakpoint-up(lg) {
                width: 30px;
            }
        }

        &__container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;
            max-height: 100%;
        }

        &__gallery {
            display: flex;
            flex-direction: column;
            max-height: 100%;

            &__upper {
                display: flex;
                min-height: 0;
                padding-top: 50px;

                @include media-breakpoint-up(lg) {
                    padding-top: 0;
                }
            }

            &__lower {
                @include media-breakpoint-up(lg) {
                    padding: 0 150px;
                }
            }

            &__control {
                display: none;

                @include media-breakpoint-up(lg) {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    min-width: 150px;
                    letter-spacing: 3px;
                    opacity: 0;
                    @include font-size(18);
                    @include line-height(18);
                }

                &--previous {
                    transform: translateX(-100px);
                }

                &--next {
                    justify-content: flex-end;
                    transform: translateX(100px);
                }

                &__trigger {
                    cursor: pointer;
                }
            }

            &__images {
                margin: 0 auto;
                opacity: 0;
                transform: translateY(100px);
                flex-grow: 1;

                @include media-breakpoint-up(lg) {
                    margin: 0;
                }

                &__image {
                    display: none;
                    opacity: 0;
                    transform: translateX(-100px);
                    position: relative;
                    height: 100%;
                    width: 100%;
                    object-fit: contain;

                    &--in {
                        display: block;
                        animation: bringIn 400ms 200ms forwards;
                    }

                    &--out {
                        display: block;
                        opacity: 1;
                        transform: none;
                        animation: pullOut 200ms forwards;
                    }
                }
            }

            &__text {
                transform: translateX(-50px);
                opacity: 0;

                &__item {
                    display: none;
                    opacity: 0;
                    transform: translateX(-50px);
                    transition-delay: 700ms;
                    padding-top: 25px;

                    @include media-breakpoint-up(lg) {
                        padding-top: 50px;
                    }

                    &--in {
                        display: block;
                        animation: bringIn 400ms 400ms forwards;
                    }

                    &--out {
                        display: block;
                        opacity: 1;
                        transform: none;
                        animation: pullOut 200ms forwards;
                    }

                    &__title {
                        font-weight: bold;
                        margin-bottom: 5px;
                    }

                    &__summary {
                        & > * {
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    &__credit {
                        margin-top: 5px;
                    }
                }
            }
        }

        .base-box {
            height: 100%;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(lg) {
                padding-top: 75px;
                padding-bottom: 75px;
            }
        }

        @keyframes bringIn {
            100% {
                opacity: 1;
                transform: none;
            }
        }

        @keyframes pushOut {
            100% {
                opacity: 0;
                transform: translateY(-100px);
            }
        }

        @keyframes pullOut {
            100% {
                opacity: 0;
                transform: translateX(100px);
            }
        }
    }
</style>