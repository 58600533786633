class Condition {
    constructor(...args) {
        this._column = '';
        this._operator = '=';
        this._value = '';
        if (args.length) {
            this.column(args.shift());

            if (args.length === 1 && args[0] !== null) {
                this.value(args[0]);
            } else {
                this.operator(args[0]).value(typeof args[1] === 'undefined' ? '' : args[1]);
            }
        }
    }

    column(column) {
        this._column = column;
        return this;
    }

    operator(operator) {
        this._operator = operator ? operator.toLowerCase() : null;
        return this;
    }

    value(value) {
        this._value = value;
        return this;
    }

    getColumn() {
        return this._column;
    }

    getOperator() {
        return this._operator;
    }

    getValue() {
        return this._value;
    }
}

export default Condition;
