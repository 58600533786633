<template>
    <div class="sub-pages">
        <BaseBox class="py-0">
            <BContainer>
                <BRow>
                    <BCol v-for="item in resolvedItems"
                          :key="item.id"
                          cols="12"
                          xl="6"
                    >
                        <BasePoster :imgSrc="item.image"
                                    :title="item.title"
                                    @click="$emit('select', item)"
                        >
                            <div v-html="item.summary"></div>
                        </BasePoster>
                    </BCol>
                </BRow>
            </BContainer>
        </BaseBox>
    </div>
</template>

<script>
    export default {
        props: {
            items: Array
        },

        computed: {
            resolvedItems() {
                return this.items.filter(item => {
                    return !item.deletedAt && item.published;
                })
            }
        }
    }
</script>

<style lang="scss">
    .sub-pages {
    }
</style>