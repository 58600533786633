import Path from '../path';
import Blender from '../../utils/blender';

class Query {
    constructor(model) {
        this._model = model;
        this._path = new Path(model._uri);
        this._relations = [];
    }

    mix(mixins) {
        Blender.on(this).mix(mixins);

        return this;
    }

    include(relation) {
        this._relations.push(relation);

        return this;
    }

    getRelations() {
        return this._relations;
    }
}

export default Query;
