<template>
    <div class="news-poster">
        <BaseImg :src="resource.image"
                 :alt="resource.imageAlt"
                 class="news-poster__img"
        />
        <a :href="resource.url"
           target="_blank"
           class="h1 news-poster__title"
        >
            {{ resource.title }}
        </a>
    </div>
</template>

<script>
    export default {
        props: {
            resource: Object
        }
    }
</script>

<style lang="scss">
    .news-poster {
        &__img {
            margin-bottom: 40px;
        }

        &__title {
            display: block;
            padding-right: 20px;
        }
    }
</style>