<template>
    <div class="photography-credit">
        <IconCamera class="photography-credit__icon"/>
        {{ strapline }}
    </div>
</template>

<script>
    import IconCamera from '../../../base/icons/IconCamera';

    export default {
        components: {
            IconCamera
        },

        props: {
            strapline: String
        }
    }
</script>

<style lang="scss">
    .photography-credit {
        display: flex;
        align-items: center;
        color: map-get($palette, "white");
        @include font-rhythm(12);

        &__icon {
            width: 15px;
            margin-right: 10px;
        }
    }
</style>