import Query from './query';
import Expression from './builders/expression';
import Model from '../model';

class Collectable extends Query {
    constructor(model) {
        super(model);

        this._filters = new Expression();
        this._sort = {};
    }

    where(...args) {
        if (this._path.includes(args[0], this._model.uri)) {
            this._path.bind(args[0], args[1] === '=' ? args[2] : args[1]);
        } else {
            this._filters.where(...args);
        }

        return this;
    }

    orWhere(...args) {
        this._filters.orWhere(...args);

        return this;
    }

    sort(property, direction = 'asc') {
        this._sort[property] = direction.toLocaleLowerCase();

        return this;
    }

    find(id) {
        let events = this._model.getEvents();

        events.fire('fetchingOne', this);

        this._path.uri = this._model.uri;

        let data = {
            params: this.getParams()
        };

        return this._model.getHttp().get(this._path.resolve() + `/${id}`, data).then(response => {
            let resource = this._model.make(Model.getAdapter().unpack(response));

            events.fire('fetchedOne', resource);

            return resource;
        });
    }

    get() {
        let events = this._model.getEvents();

        events.fire('fetchingMany', this);

        this._path.uri = this._model.uri;

        let data = {
            params: this.getParams()
        };

        return this._model.getHttp().get(this._path.resolve(), data).then(response => {
            let collection = this._model.collection(Model.getAdapter().unpack(response));

            events.fire('fetchedMany', collection);

            return collection;
        });

    }

    first() {
        return this.get().then(result => {
            return result.length ? result[0] : null;
        });
    }

    getOrders() {
        return this._sort;
    }

    getFilters() {
        return this._filters;
    }

    getParams() {
        return Model.getAdapter().params(this);
    }

    hasCondition(condition) {
        let hasCondition = false;

        this._filters.forEach(filter => {
            if (filter.property === condition) {
                hasCondition = true;
            }
        });

        if (!hasCondition) {
            hasCondition = this._path.isBound(condition);
        }

        return hasCondition;
    }
}

export default Collectable;
