<template>
    <div class="square-img"
         :class="classes"
         @click="$emit('click')"
    >
        <div v-if="rollover"
             class="square-img__mask"
        ></div>
        <BaseImg :src="src"/>
    </div>
</template>

<script>
    export default {
        props: {
            src: String,
            rollover: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            classes() {
                return this.rollover ? ['square-img--rollover'] : [];
            }
        }
    }
</script>

<style lang="scss">
    .square-img {
        position: relative;
        padding-bottom: 100%;
        overflow: hidden;

        &--rollover {
            &:hover {
                .square-img__mask {
                    opacity: .5;
                }

                .base-img {
                    transform: scale(1.15);
                }
            }

            .square-img__mask {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: map-get($palette, "black");
                opacity: 0;
                transition: opacity 500ms;
                z-index: 1;
            }

            .base-img {
                transition: transform 300ms;
            }
        }

        &__mask {
            display: none;
        }

        .base-img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: cover;
        }
    }
</style>