import Resource from './resource';
import Model from '../model';

class Collectable extends Resource {
    _create() {
        let events = this._model.getEvents();
        let adapter = Model.getAdapter();

        events.fire('creating', this);

        this._path.uri = this._model.uri;

        return this._model.getHttp().post(this._path.prepare(this).resolve(), Model.getAdapter().repack(this._model.type, this.$attributes())).then(response => {
            this.$fill(adapter.unpack(response));

            events.fire('created', this);

            return this;
        }).catch(error => {
            throw adapter.unpack(error.response);
        });
    }

    _update() {
        let adapter = Model.getAdapter();
        let events = this._model.getEvents();

        events.fire('updating', this);

        this._path.uri = this._model.uri;

        return this._model.getHttp().patch(this._path.prepare(this).resolve() + `/${this.id}`, Model.getAdapter().repack(this._model.type, this.$attributes())).then(response => {
            this.$fill(adapter.unpack(response));

            events.fire('updated', this);

            return this;
        }).catch(error => {
            throw adapter.unpack(error.response);
        });
    }

    $save() {
        return this.$resolved() ? this._update() : this._create();
    }

    $destroy() {
        if (this.$resolved()) {
            let adapter = Model.getAdapter();
            let events = this._model.getEvents();

            events.fire('deleting', this);

            this._path.uri = this._model.uri;

            return this._model.getHttp().delete(this._path.prepare(this).resolve() + `/${this.id}`).then(() => {
                events.fire('deleted', this);

                return true;
            }).catch(error => {
                throw adapter.unpack(error.response);
            });
        }
    }

    $resolved() {
        return ('id' in this && this.id);
    }
}

export default Collectable;
