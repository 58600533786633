<template>
    <div class="social-links">
        <a :href="instagramLink"
           class="social-links__item"
           target="_blank"
        >
            <IconInstagram/>
        </a>
        <a :href="twitterLink"
           class="social-links__item"
           target="_blank"
        >
            <IconTwitter/>
        </a>
    </div>
</template>

<script>
    export default {
        computed: {
            instagramLink() {
                return this.$store.getters.keyValue('social-media', 'instagram').value;
            },

            twitterLink() {
                return this.$store.getters.keyValue('social-media', 'twitter').value;
            }
        }
    }
</script>

<style lang="scss">
    .social-links {
        display: flex;

        &__item {
            display: block;
            width: 30px;
            cursor: pointer;
            margin-right: 10px;

            @include media-breakpoint-up(lg) {
                width: 42px;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                .icon-instagram, .icon-twitter {
                    &__symbol {
                        fill: map-get($palette, "heliotrope") !important;
                    }
                }

                circle {
                    stroke: map-get($palette, "heliotrope") !important;
                }
            }

            .icon-instagram, .icon-twitter {
                &__symbol {
                    transition: fill 400ms;
                }
            }

            circle {
                transition: stroke 400ms;
            }
        }
    }
</style>