import Condition from './condition';

class Expression {
    constructor() {
        this._items = [];
    }

    and(...args) {
        return this.add(
            'AND',
            args[0] instanceof Expression ? args[0] : this.constraint(...args)
        );
    }

    where(...args) {
        return this.and(...args);
    }

    or(...args) {
        return this.add(
            'OR',
            args[0] instanceof Expression ? args[0] : this.constraint(...args)
        );
    }

    orWhere(...args) {
        return this.or(...args);
    }

    add(operator, constraint) {
        this._items.push({
            operator: operator,
            constraint: constraint
        });

        return this;
    }

    constraint(...args)
    {
        if (typeof args[0] === 'function') {
            let expression = new Expression();
            args[0](expression);
            return expression;
        }

        return new Condition(...args);
    }

    count() {
        return this._items.length;
    }

    getItems() {
        return this._items;
    }
}

export default Expression;