<template>
    <div class="experience-overlay"
         :class="classes"
         ref="container"
    >
        <template v-if="page">
            <BaseBox class="py-0">
                <BContainer>
                    <IconCross @click="$emit('close')"
                               class="experience-overlay__close"
                    />
                    <SectionHeading element="h1">
                        {{ parentPage.navTitle }}
                    </SectionHeading>
                    <div class="experience-overlay__info">
                        <h2 class="h1">
                            {{ page.title }}
                        </h2>
                        <div v-html="page.body"></div>
                    </div>
                </BContainer>
                <ImgWall v-if="page.galleries && page.galleries.length"
                         :items="page.galleries"
                         @click="showGallery"
                />
            </BaseBox>
            <LightBox :visible="showLightBox"
                      :items="activeGallery"
                      @close="showLightBox = false"
            />
        </template>
    </div>
</template>

<script>
    import SectionHeading from '../../../base/SectionHeading';
    import ImgWall from '../../../layout/ImgWall';
    import LightBox from '../../../interactions/LightBox';
    import IconCross from '../../../base/icons/IconCross';

    const bodyScrollLock = require('body-scroll-lock');
    const disableBodyScroll = bodyScrollLock.disableBodyScroll;
    const enableBodyScroll = bodyScrollLock.enableBodyScroll;

    export default {
        components: {
            SectionHeading,
            ImgWall,
            LightBox,
            IconCross
        },

        props: {
            page: Object,
            active: {
                type: Boolean,
                default: false
            }
        },

        watch: {
            active(val) {
                if (val) {
                    disableBodyScroll(this.$refs.container);
                } else {
                    enableBodyScroll(this.$refs.container);
                }
            }
        },

        data() {
            return {
                showLightBox: false,
                activeGallery: null
            }
        },

        computed: {
            classes() {
                return this.active ? ['experience-overlay--active'] : [];
            },

            parentPage() {
                return this.$store.getters.pageBySlug('experience');
            },

            galleries() {
                if (!this.page.galleries) {
                    return [];
                }

                return this.page.galleries.filter(item => {
                    return !item.deletedAt;
                });
            }
        },

        methods: {
            showGallery(item) {
                if (item.galleryImages.length) {
                    this.activeGallery = item.galleryImages.filter(item => {
                        return !item.deletedAt;
                    });

                    window.setTimeout(() => {
                        this.showLightBox = true;
                    }, 50);
                }
            }
        },
    }
</script>

<style lang="scss">
    .experience-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: map-get($palette, "white");
        top: 0;
        left: 0;
        z-index: 2;
        pointer-events: none;
        opacity: 0;
        transition: opacity 500ms;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 35px 0;

        @include media-breakpoint-up(lg) {
            padding: 75px 0;
        }

        &--active {
            opacity: 1;
            pointer-events: auto;
        }

        &__info {
            max-width: 510px;
            margin-bottom: 40px;
        }

        &__close {
            width: 25px;
            margin-left: auto;
            cursor: pointer;

            @include media-breakpoint-up(lg) {
                width: 30px;
            }

            polygon {
                fill: map-get($palette, "silver-chalice");
            }
        }

        .section-heading {
            padding-top: 0;
        }
    }
</style>