import Model from './model';
import Query from './queries/collectable';
import Resource from './resources/collectable';

class Collectable extends Model {
    queryBuilder() {
        return new Query(this);
    }

    resource() {
        return new Resource(this);
    }

    create(attributes) {
        return this.make(attributes).$save();
    }

    collection(data) {
        return data.map(obj => {
            return this.make(obj);
        });
    }

    all() {
        return this.query().get();
    }
}

export default Collectable;
