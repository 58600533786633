<template>
    <div class="round-img"
         @click="$emit('click')"
    >
        <BaseImg :src="src"/>
    </div>
</template>

<script>
    export default {
        props: {
            src: String
        }
    }
</script>

<style lang="scss">
    .round-img {
        position: relative;
        padding-bottom: 100%;
        border-radius: 50%;
        overflow: hidden;

        .base-img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: cover;
        }
    }
</style>