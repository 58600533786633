<template>
    <img :src="resolvedSrc"
         class="base-img"
         @click="$emit('click')"
         @animationend="$emit('animationend')"
    >
</template>

<script>
    export default {
        props: {
            src: String
        },

        computed: {
            resolvedSrc() {
                return `${process.env.VUE_APP_CDN_URL}/${this.src}`;
            }
        }
    }
</script>

<style lang="scss">
    .base-img {
        display: block;
        width: 100%;
        height: auto;
    }
</style>