<template>
    <section class="page-section">
        <div v-if="carouselImages.length"
             class="page-section__carousel"
        >
            <component :is="carouselComponent"
                       v-bind="carouselProps"
            />
            <div class="page-section__carousel__flourish">
                <IconTilde/>
            </div>
        </div>
        <BaseBox class="py-0 page-section__title">
            <BContainer>
                <SectionHeading>
                    {{ resource.navTitle }}
                </SectionHeading>
            </BContainer>
        </BaseBox>
        <div class="page-section__content">
            <slot></slot>
        </div>
    </section>
</template>

<script>
    import HeroCarousel from '../../partials/HeroCarousel';
    import QuoteCarousel from '../../partials/QuoteCarousel';
    import SectionHeading from '../../../../base/SectionHeading';
    import IconTilde from '../../../../base/icons/IconTilde';

    export default {
        components: {
            HeroCarousel,
            QuoteCarousel,
            SectionHeading,
            IconTilde
        },

        props: {
            resource: Object,
            carouselComponent: {
                type: String,
                default: 'HeroCarousel'
            }
        },

        computed: {
            carouselProps() {
                let props = {
                    items: this.carouselImages
                };

                if (this.carouselComponent === 'QuoteCarousel') {
                    props.backgroundImageSrc = this.resource.image;
                    props.backgroundImageAlt = this.resource.imageAlt;
                    props.backgroundImageCopyright = this.resource.imageCopyright;
                }

                return props;
            },

            carouselImages() {
                if (!this.resource || !this.resource.carouselImages) {
                    return [];
                }

                return this.resource.carouselImages.filter(item => {
                    return !item.deletedAt;
                });
            }
        }
    }
</script>

<style lang="scss">
    .page-section {
        position: relative;

        &__carousel {
            position: relative;

            &__flourish {
                position: absolute;
                bottom: 25%;
                left: -150%;
                right: -150%;
                transform: translateY(100%);
                z-index: -1;

                @include media-breakpoint-up(sm) {
                    left: -100%;
                    right: -100%;
                }

                @include media-breakpoint-up(md) {
                    left: -70%;
                    right: -70%;
                }

                @include media-breakpoint-up(lg) {
                    left: -40%;
                    right: -40%;
                }

                .icon-tilde {
                    fill: map-get($palette, "white-pointer");
                }
            }
        }

        .section-heading {
            padding-top: 30px;

            @include media-breakpoint-up(lg) {
                padding-top: 60px;
            }

            @include media-breakpoint-up(xl) {
                padding-top: 90px;
            }
        }
    }
</style>