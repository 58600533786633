<template>
    <DefaultSection :resource="resource"
                    :id="resource.slug"
                    carousel-component="QuoteCarousel"
                    class="experience-section"
    >
        <SubPages route="experience"
                  :items="resource.pages"
                  @select="select"
        />
        <ExperienceOverlay :page="subpage"
                           :active="overlayActive"
                           @close="overlayActive = false"
        />
    </DefaultSection>
</template>

<script>
    import DefaultSection from './DefaultSection';
    import SubPages from '../SubPages';
    import ExperienceOverlay from '../ExperienceOverlay';

    export default {
        components: {
            DefaultSection,
            SubPages,
            ExperienceOverlay
        },

        props: {
            resource: Object
        },

        data() {
            return {
                subpage: null,
                overlayActive: false
            }
        },

        methods: {
            select(page) {
                this.subpage = page;
                this.overlayActive = true;
            }
        }
    }
</script>