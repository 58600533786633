<template>
    <div v-if="items.length"
         class="hero-carousel"
    >
        <slick :options="slickOptions">
            <div v-for="item in items"
                 :key="item.id"
                 class="hero-carousel__item"
            >
                <BaseImg :src="item.image"
                         :alt="item.imageAlt"
                />
                <BContainer class="hero-carousel__item__credit-container">
                    <BaseBox class="py-0">
                        <PhotographyCredit :strapline="item.imageCopyright"/>
                    </BaseBox>
                </BContainer>
            </div>
        </slick>
    </div>
</template>

<script>
    import Slick from 'vue-slick';
    import PhotographyCredit from './PhotographyCredit';

    export default {
        components: {
            Slick,
            PhotographyCredit
        },

        props: {
            items: Array
        },

        data() {
            return {
                slickOptions: {
                    slidesToShow: 1,
                    arrows: false,
                    dots: true,
                    responsive: [
                        {
                            breakpoint: 1200,
                            settings: {
                                dots: false
                            }
                        }
                    ]
                },
            };
        }
    }
</script>

<style lang="scss">
    .hero-carousel {
        &__item {
            position: relative;
            height: 350px;

            @include media-breakpoint-up(lg) {
                height: 450px;
            }

            @include media-breakpoint-up(xl) {
                height: 725px;
            }

            &__overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: map-get($palette, "black");
                opacity: .4;
            }

            &__credit-container {
                position: absolute;
                bottom: 20px;
                left: 0;
                right: 0;
                z-index: 1;

                .photography-credit {
                    width: fit-content;
                    margin-left: auto;
                }
            }

            .base-img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .slick-dots {
            display: none;

            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                position: absolute;
                bottom: 0;
                width: 100%;
                list-style: none;
                margin-bottom: 50px;

                li {
                    margin: 0 5px;

                    button {
                        display: block;
                        background: none;
                        border: solid 2px map-get($palette, "white");
                        border-radius: 50%;
                        transition: background-color 400ms;
                        width: 20px;
                        height: 20px;
                        overflow: hidden;
                        text-indent: 25px;
                        outline: none !important;
                    }

                    &.slick-active {
                        button {
                            background-color: map-get($palette, "white");
                        }
                    }
                }
            }
        }
    }
</style>