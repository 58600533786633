import axios from 'axios';
import Collectable from '../services/model/collectable';

class Factory {
    static section() {
        return this.collectable('sections');
    }

    static page() {
        return this.collectable('pages');
    }

    static portfolio() {
        return this.collectable('portfolio');
    }

    static news() {
        return this.collectable('news');
    }

    static keyValue() {
        return this.collectable('key-values');
    }

    static collectable(endpoint) {
        return new Collectable(axios, `${process.env.VUE_APP_API_URL}/${endpoint}`);
    }
}

export default Factory;