<template>
    <header :style="headerStyles"
            id="the-header"
    >
        <BaseBox class="py-0">
            <BContainer>
                <div id="the-header__upper-bar">
                    <div id="the-header__contact">
                        <IconEnvelope/>
                        <a :href="`mailto:${contactEmail}`">
                            {{ contactEmail }}
                        </a>
                    </div>
                    <SocialLinks/>
                </div>
                <div id="the-header__lower-bar">
                    <TheLogo :style="logoStyles"/>
                    <TheNav/>
                </div>
            </BContainer>
        </BaseBox>
    </header>
</template>

<script>
    import SocialLinks from '../../../social/SocialLinks';
    import TheLogo from './TheLogo';
    import TheNav from './TheNav';

    export default {
        components: {
            SocialLinks,
            TheLogo,
            TheNav
        },

        data() {
            return {
                windowWidth: window.innerWidth,
                scrollY: window.scrollY,
            }
        },

        computed: {
            scaleHeader() {
                return this.windowWidth > 1199 && this.scrollY !== 0;
            },

            resolvedScrollY() {
                return this.scrollY < 100 ? this.scrollY : 100;
            },

            logoStyles() {
                if (!this.scaleHeader) {
                    return {};
                }

                let scale = 1 + (this.resolvedScrollY/100);

                return {
                    width: `${380/scale}px`,
                    marginTop: `-${this.resolvedScrollY/2}px`
                }
            },

            headerStyles() {
                if (!this.scaleHeader) {
                    return {};
                }

                let paddingBottom = 50 - this.resolvedScrollY;

                if (paddingBottom < 25) {
                    paddingBottom = 25;
                }

                return {
                    paddingBottom: `${paddingBottom}px`
                }
            },

            contactEmail() {
                return this.$store.getters.keyValue('contact', 'contact-email').value;
            },
        },

        mounted() {
            window.addEventListener('scroll', () => {
                this.windowWidth = window.innerWidth;
                this.scrollY = window.scrollY;
            });
        }
    }
</script>

<style lang="scss">
    #the-header {
        position: fixed;
        z-index: 2;
        padding: 15px 0 30px 0;
        width: 100%;
        background-color: map-get($palette, "white");

        @include media-breakpoint-up(xl) {
            padding: 15px 0 50px 0;
        }

        &__contact {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            @include font-rhythm(11);

            @include media-breakpoint-up(xl) {
                letter-spacing: 2.5px;
                @include font-rhythm(12);
            }

            &:hover {
                .icon-envelope {
                    path {
                        fill: map-get($palette, "heliotrope") !important;
                    }
                }
            }

            .icon-envelope {
                width: 18px;
                margin-right: 7px;

                path {
                    transition: fill 400ms;
                }
            }
        }

        &__upper-bar {
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(xl) {
                margin-bottom: 20px;
            }
        }

        &__lower-bar {
        }

        #the-logo {
            width: 160px;
            margin: 0 auto;

            @include media-breakpoint-up(xl) {
                width: 380px;
                margin-bottom: 30px;
            }
        }

        #the-nav {
        }
    }
</style>