// Styles
import './styles/app.scss';

// Js
import './providers/model';
import Vue from './providers/vue';
import router from './providers/vue-router';
import store from './store';
import App from './App.vue';
import VueGtag from "vue-gtag";
import Models from './models/factory';

Vue.use(VueGtag, {
    config: { id: "UA-181056630-1" }
}, router);

let promises = [];

promises.push(
    Models.section()
        .query()
        .include('pages.pages')
        .include('pages.carousel-images')
        .include('pages.galleries.gallery-images')
        .where('published', 1)
        .where('deleted_at', null)
        .where('pages.deleted_at', null)
        .where('pages.published', 1)
        .sort('sort')
        .sort('pages.pages.sort')
        .sort('pages.carousel-images.sort')
        .sort('pages.galleries.sort')
        .sort('pages.galleries.gallery-images.sort')
        .get()
        .then(sections => {
            store.commit('fillHierarchy', sections);

            return true;
        })
);

promises.push(
    Models.keyValue().query().get().then(keyValues => {
        store.commit('fillKeyValues', keyValues);

        return true;
    })
);

Promise.all(promises).then(() => {
    new Vue({
        router,
        store,
        render: h => h(App),
    }).$mount('#app');
});