class Sort {
    static serialize(orders) {
        let items = [];

        for (let item in orders) {
            items.push(
                orders[item] === 'desc' ? `-${item}` : item
            )
        }

        return items.join();
    }
}

export default Sort;