<template>
    <BContainer class="img-wall">
        <BRow v-for="(row, rowIndex) in rows"
              :key="rowIndex"
              class="img-wall__row"
        >
            <BCol v-for="(item, columnIndex) in row"
                  :key="columnIndex"
                  cols="12"
                  lg="6"
                  class="img-wall__col"
                  :order-lg="(rowIndex % 2) ? columnIndex === 1 ? 0 : 1 : ''"
            >
                <ImgWallBrick :content="item"
                              @click="$emit('click', $event)"
                />
            </BCol>
        </BRow>
    </BContainer>
</template>

<script>
    import ImgWallBrick from './ImgWallBrick';

    const chunk = (arr, size) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
            arr.slice(i * size, i * size + size)
        );

    export default {
        components: {
            ImgWallBrick
        },

        props: {
            items: Array
        },

        computed: {
            rows() {
                if (!this.items.length) {
                    return [];
                }

                let rows = [];
                let chunked = chunk(this.items.filter(item => {
                    return !item.deletedAt;
                }), 3);

                for (let i = 0; i < chunked.length; i++) {
                    let rowItems = [
                        chunked[i].slice(0, 2)
                    ];

                    if (chunked[i].length > 2) {
                        rowItems.push(chunked[i][2]);
                    }

                    rows.push(rowItems);
                }

                console.log(rows);

                return rows;
            }
        }
    }
</script>

<style lang="scss">
    .img-wall {
        margin-top: -7px;

        &__row {
            margin: 0 -7px;

            &:nth-child(even) {
                .img-wall__brick {
                    &__row {
                        &--upper {
                            .img-wall__brick__col {
                                margin-left: 0;
                            }
                        }

                        &--lower {
                            .img-wall__brick__col {
                                margin-left: auto;
                            }
                        }
                    }
                }
            }
        }
    }
</style>