<template>
    <DefaultSection :resource="resource"
                    :id="resource.slug"
                    class="press-section"
    >
        <BaseBox class="experience-section__box py-0">
            <BContainer>
                <NewsCarousel :items="news"/>
            </BContainer>
        </BaseBox>
    </DefaultSection>
</template>

<script>
    import Models from '../../../../../models/factory';
    import DefaultSection from './DefaultSection';
    import NewsCarousel from '../NewsCarousel';

    export default {
        components: {
            DefaultSection,
            NewsCarousel
        },

        props: {
            resource: Object
        },

        data() {
            return {
                news: []
            }
        },

        mounted() {
            Models.news().query().where('published', 1).where('deleted_at', null).sort('sort').get().then(stories => {
                this.news = stories;
            });
        }
    }
</script>

<style lang="scss">
    .experience-section {
        &__box {
            padding-right: 0;

            @include media-breakpoint-up(sm) {
                padding-right: 35px;
            }
        }
    }
</style>