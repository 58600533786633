import VueRouter from 'vue-router';
import routes from '../routes';

const router = new VueRouter({
    routes,
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    if (to.name !== 'home') {
        window.setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 800);
    }

    next();
});

export default router;