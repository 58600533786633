class Unpacker {
    constructor(payload) {
        this._payload = payload;
        this._relationMap = {};
    }

    status() {
        return this._payload.status;
    }

    error() {
        return this._payload.data.errors;
    }

    data() {
        let data = this._payload.data.data;

        this.buildRelationMap();

        if (!Array.isArray(data)) {
            return this.resource(data);
        }

        return this.collection(data);
    }

    collection(collection) {
        return collection.map(item => {
            return this.resource(item);
        });
    }

    resource(resource) {
        let attributes = Object.assign({}, {id: resource.id}, resource.attributes);

        if ('relationships' in resource) {
            attributes = Object.assign({}, attributes, this.relations(resource.relationships));
        }

        return attributes;
    }

    relations(relationships) {
        let relations = {};

        for (let name in relationships) {
            relations[name] = [];

            if (Array.isArray(relationships[name].data)) {
                relationships[name].data.forEach(relation => {
                    let resource = this.mapRelation(name, relation);

                    if (resource) {
                        relations[name].push(resource);
                    }
                });
            } else {
                let resource = null;

                if (relationships[name].data) {
                    resource = this.mapRelation(name, relationships[name].data);
                }

                relations[name] = resource;
            }
        }

        return relations;
    }

    mapRelation(name, relation) {
        let resource = null;
        let key = `${name}:${relation.id}`;

        if (key in this._relationMap) {
            resource = this.resource(this._relationMap[key]);
        }

        return resource;
    }

    buildRelationMap() {
        if (typeof this._payload.data.included !== 'undefined') {
            this._payload.data.included.forEach(included => {
                this._relationMap[`${included.type}:${included.id}`] = included;
            });
        }

        return this;
    }
}

export default Unpacker;
