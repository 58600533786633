<template>
    <BaseBox v-if="resource.body || resource.summary"
             class="page-summary py-0"
    >
        <BContainer>
            <BRow>
                <BCol cols="12"
                      lg="7"
                >
                    <div class="page-summary__image page-summary__image--mobile">
                        <RoundImg v-if="resource.image"
                                  :src="resource.image"
                        />
                    </div>
                    <h3 v-if="resource.title"
                        v-html="resource.title"
                        class="h1"
                    ></h3>
                    <div v-if="resource.body"
                         v-html="resource.body"
                    ></div>
                </BCol>
                <BCol cols="12"
                      lg="5"
                      class="page-summary__image page-summary__image--desktop"
                >
                    <RoundImg v-if="resource.image"
                              :src="resource.image"
                    />
                </BCol>
            </BRow>
        </BContainer>
    </BaseBox>
</template>

<script>
    export default {
        props: {
            resource: Object
        }
    }
</script>

<style lang="scss">
    .page-summary {
        &__image {
            &--mobile {
                width: 110px;
                float: right;
                margin: 0 0 35px 35px;

                @include media-breakpoint-up(sm) {
                    width: 200px;
                }

                @include media-breakpoint-up(md) {
                    width: 250px;
                }

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

            &--desktop {
                display: none;
                padding-left: 140px;

                @include media-breakpoint-up(lg) {
                    display: block;
                }
            }
        }
    }
</style>