import Unpacker from './unpacker';
import CollectableQuery from '../../queries/collectable';
import ExpressionSerializer from './serializers/expression';
import RelationSerializer from './serializers/relation';
import SortSerializer from './serializers/sort';
import Repacker from "./repacker";

class Adapter {
    static unpack(response) {
        let unpacker = new Unpacker(response);

        switch(unpacker.status()) {
            case 200:
            case 201:
            case 204:
                return unpacker.data();

            default:
                return unpacker.error();
        }
    }

    static repack(type, data) {
        let repacker = new Repacker(type, data);

        return repacker.data();
    }

    static params(query) {
        let params = {};
        let relations = query.getRelations();

        if(relations.length) {
            params.include = RelationSerializer.serialize(relations);
        }

        if(query instanceof CollectableQuery) {
            let filters = query.getFilters();
            let orders = query.getOrders();

            if(Object.keys(filters).length > 0) {
                params.filter = ExpressionSerializer.serialize(filters);
            }

            if(Object.keys(orders).length > 0) {
                params.sort = SortSerializer.serialize(orders);
            }
        }

        return params;
    }
}

export default Adapter;
