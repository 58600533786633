import Dispatcher from '../events/dispatcher';
import Blender from '../utils/blender';

class Model {
    constructor(http, uri) {
        this._http = http;
        this._uri = uri;
        this._events = new Dispatcher();

        this._mixins = {
            query: {},
            resource: {}
        };
    }

    mix(mixins) {
        if ('model' in mixins) {
            Blender.on(this).mix(mixins.model);
            delete mixins.model;
        }

        for (let prop in mixins) {
            if (prop in this._mixins) {
                this._mixins[prop] = Blender.mix(this._mixins[prop], mixins[prop]);
            }
        }

        return this;
    }

    observe(observer) {
        this._events.listen(observer);
    }

    static setAdapter(adapter) {
        this._adapter = adapter;
    }

    static getAdapter() {
        return this._adapter;
    }

    getHttp() {
        return this._http;
    }

    getEvents() {
        return this._events;
    }

    make(attributes) {
        this._events.fire('making', attributes);

        // noinspection JSUnresolvedFunction
        let resource = this.resource().$fill(attributes);
        this._events.fire('made', resource);

        return resource.$mix(this._mixins.resource);
    }

    query() {
        // noinspection JSUnresolvedFunction
        let builder = this.queryBuilder();

        return builder.mix(this._mixins.query);
    }
}

export default Model;
