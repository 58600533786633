<template>
    <div class="base-box">
        <slot></slot>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss">
    .base-box {
        padding: 35px;
    }
</style>