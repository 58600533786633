import HomePage from './components/views/templates/HomePage';

const routes = [
    {
        name: 'home',
        path: '/:slug?',
        component: HomePage,
        props: true
    }
];

export default routes;